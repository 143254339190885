import React from "react";

export default function Location() {
    return (
    <main className='bg-white p-4'>
      <div className='flex  justify-center  items-center my-10'>
        <div className="w-2/5">
            <h3 className="text-2xl mb-6">Bend Office</h3>
            <p className="text-base my-4">1900 NE Division St Suite 105 <br/>Bend, OR 97701</p>
            <p className="text-sm">(Located in the Whistlestop Business Center)</p>
        </div>
        <div className="w-3/5">        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14447.030892675692!2d-121.31651570798986!3d44.069851934291734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54b8c8a3172fdfef%3A0xe66fd5e9365ab0aa!2s1900%20NE%20Division%20St%20%23105%2C%20Bend%2C%20OR%2097701%2C%20USA!5e0!3m2!1sen!2sin!4v1712569177999!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className='flex  justify-center  items-center my-10'>
        <div className="w-2/5">
            <h3 className="text-2xl mb-6">Salem Office </h3>
            <p className="text-base my-4">740 Hawthorne Ave NE <br/>Salem, OR 97301</p>
            <p className="text-sm">(Center Street Plaza)</p>
        </div>
        <div className="w-3/5">        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6339.970463507384!2d-122.99486434994948!3d44.940907249479274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54bffedc6dafefbb%3A0x9a9dabdeb09c3043!2s740%20Hawthorne%20Ave%20NE%2C%20Salem%2C%20OR%2097301%2C%20USA!5e0!3m2!1sen!2sin!4v1712568358415!5m2!1sen!2sin"  width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      {/*<div className='flex  justify-center  items-center my-10'>*/}
      {/*  <div className="w-2/5">*/}
      {/*      <h3 className="text-2xl mb-6">Woodburn Office</h3>*/}
      {/*      <p className="text-base my-4">1060 Mt Hood Ave <br/>Woodburn, OR 97071</p>*/}
      {/*      <p className="text-sm">(located inside the Woodburn Police Department)</p>*/}
      {/*  </div>*/}
      {/*  <div className="w-3/5">        */}
      {/*  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14180.570719574927!2d-122.85321471221151!3d45.151019371465786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54955c32cdbd219d%3A0x1a20369441a1d367!2s1060%20Mt%20Hood%20Ave%2C%20Woodburn%2C%20OR%2097071%2C%20USA!5e0!3m2!1sen!2sin!4v1712568428507!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className='flex  justify-center  items-center my-10'>
        <div className="w-2/5">
            <h3 className="text-2xl mb-6">Clackamas Office</h3>
            <p className="text-base my-4">15804 SE 114th Ave <br/>Clackamas, OR 97015</p>
            <p className="text-sm">(Located in Imperial Plaza; BLDG 6)​​​​​​​​</p>
        </div>
        <div className="w-3/5">        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6287.954650473362!2d-122.55009845175839!3d45.41003752709694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549575e51a65450f%3A0x682ffeff9a5702e7!2s15804%20SE%20114th%20Ave%2C%20Clackamas%2C%20OR%2097015%2C%20USA!5e0!3m2!1sen!2sin!4v1712568589435!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <div className='flex  justify-center  items-center my-10'>
        <div className="w-2/5">
            <h3 className="text-2xl mb-6">Portland Office</h3>
            <p className="text-base my-4">2442 SE 101st Avenue, Suite 303 <br/>Portland, OR 97216</p>
            <p className="text-sm">(Located in the Parkway Plaza Bldg; West Entrance)</p>
        </div>
        <div className="w-3/5">        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6277.410636336549!2d-122.56345709494724!3d45.504672076205246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495a1b7a9b00001%3A0xc6ec41602222f576!2s2442%20SE%20101st%20Ave%20UNIT%20303%2C%20Portland%2C%20OR%2097216%2C%20USA!5e0!3m2!1sen!2sin!4v1712569108494!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <div className='flex  justify-center  items-center my-10'>
        <div className="w-2/5">
            <h3 className="text-2xl mb-6">Beaverton</h3>
            <p className="text-base my-4">3861 SW Hall BLVD<br/>Beaverton, OR 97005</p>
            <p className="text-sm">(located in Beavercreek on the second floor)</p>
        </div>
        <div className="w-3/5">        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14095.527775961717!2d-122.8111893379186!3d45.491817674350294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950ea3d4311e3d%3A0xd0d036dad8d9ea41!2s3861%20SW%20Hall%20Blvd%2C%20Beaverton%2C%20OR%2097005%2C%20USA!5e0!3m2!1sen!2sin!4v1712569039821!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <div className='flex  justify-center  items-center my-10'>
        <div className="w-2/5">
            <h3 className="text-2xl mb-6">Hillsboro</h3>
            <p className="text-base my-4">315 E Main Street, Suite 309 <br/>Hillsboro, OR 97123</p>
            
        </div>
        <div className="w-3/5">        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6275.337662587812!2d-122.99099661611602!3d45.52325934600244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54951abb34e9691d%3A0x9d79ea5f9303f1cf!2s315%20E%20Main%20St%20Ste%20309%2C%20Hillsboro%2C%20OR%2097123%2C%20USA!5e0!3m2!1sen!2sin!4v1712568979023!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      {/*<div className='flex  justify-center  items-center my-10'>*/}
      {/*  <div className="w-2/5">*/}
      {/*      <h3 className="text-2xl mb-6">Scappoose Office</h3>*/}
      {/*      <p className="text-base my-4">33611 E. Columbia Ave, Suite B <br/>Scappoose, OR 97056</p>*/}
      {/*      */}
      {/*  </div>*/}
      {/*  <div className="w-3/5">        */}
      {/*  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6249.094231826709!2d-122.8790660149887!3d45.758061211297246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549454f649ec8577%3A0xdb4d1fb522f69bd2!2s33611%20E%20Columbia%20Ave%20b%2C%20Scappoose%2C%20OR%2097056%2C%20USA!5e0!3m2!1sen!2sin!4v1712568910999!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
      {/*  </div>*/}
      {/*</div>*/}

     
    </main>
);
}